/** @jsx jsx */
import { jsx } from "theme-ui";

import React, { useState } from "react";
import { Flex, Box, Text } from "theme-ui";
//import { Helmet } from "react-helmet";
import Seo from "../../../../components/Seo";
import loadable from "@loadable/component";
import { CarouselProvider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import strings from "./strings.json";
import substrDescription from "../../utils/substrDescription";
// import ProductCounter from '../../components/ProductCounter';
import ProductCounter from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/ProductCounter";
import Divider from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/Divider";
import Breadcrumbs from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/Breadcrumbs";

// import ProductGalleryThumbnails from './ProductGalleryThumbnails';
import ProductGalleryThumbnails from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/ProductGalleryThumbnails";
import ProductGalleryCarousel from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/ProductGalleryCarousel";
import { CurrentVariantContextProvider } from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/CurrentVariantContext";
import ProductVariantSelector from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/ProductVariantSelector";
import ProductVariantAddToCart from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/ProductVariantAddToCart";
import ProductVariantPrice from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/ProductVariantPrice";
import ProductVariantSku from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/ProductVariantSku";
import ProductDescription from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/ProductDescription";
import ProductReviews from "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/ProductReviews";

// react-payment-icons-inline heavily increases webpack bundle size. Need to find alternative solution. Will disable it for now.
// const Payments = loadable(() => import('../../components/Payments'));
const ShareButtons = loadable(() =>
  import(
    "@gatsbystorefront/gatsby-theme-storefront-shopify/src/components/ShareButtons"
  )
);
const DescriptionBox = loadable(() =>
  import(
    "@gatsbystorefront/gatsby-theme-storefront-shopify/src/templates/product/DescriptionBox"
  )
);

const {
  productQuantityLabel,
  paymentsLabel,
  shareButtonsLabel,
  vendorLabel,
  productTypeLabel,
} = strings;

function ProductPage({ data, pageContext, location }) {
  const [currentAmount, setCurrentAmount] = useState(1);
  const {
    product: {
      title,
      description,
      descriptionHtml,
      images,
      variants,
      options,
      vendor,
      productType,
      cmsConnection,
      reviewsConnection: reviews,
    },
  } = data;

  const cmsData = cmsConnection || {};

  const {
    shortDescription,
    descriptionHtml: withoutShortDescription,
    descriptionSections: sections,
  } = cmsData;

  // There are cases when product doesn't belong to any collection.
  // In this case we need to set a guard in case "collection" and "fields" props undefined.
  const { collection } = data || {};
  const { fields } = collection || {};
  const { title: collectionTitle } = collection || {
    title: null,
  };
  const { shopifyThemePath: collectionPath } = fields || {
    shopifyThemePath: null,
  };

  const { cartUrl } = pageContext;
  const { shareButtons, productImagesCarouselProps, reviewsNumberPerPage } =
    data.store.siteMetadata.gatsbyStorefrontConfig;

  function increaseAmount() {
    setCurrentAmount((a) => a + 1);
  }

  function decreaseAmount() {
    setCurrentAmount((a) => (a <= 1 ? 1 : a - 1));
  }

  return (
    <>
      <Seo
        title={title}
        description={
          shortDescription
            ? substrDescription(shortDescription, 158)
            : substrDescription(description, 158)
        }
        image={images[0].originalSrc}
      />

      <CarouselProvider
        naturalSlideWidth={
          productImagesCarouselProps.naturalSlideWidth
            ? productImagesCarouselProps.naturalSlideWidth
            : images[0]
            ? images[0].localFile.childImageSharp.main.width
            : 1
        }
        naturalSlideHeight={
          productImagesCarouselProps.naturalSlideHeight
            ? productImagesCarouselProps.naturalSlideHeight
            : images[0]
            ? images[0].localFile.childImageSharp.main.height
            : 1
        }
        totalSlides={images.length}
      >
        <Flex
          pt={3}
          px={2}
          mx="auto"
          sx={{ flexDirection: ["column", null, "row"], maxWidth: 1300 }}
        >
          {images && images.length > 1 ? (
            <Box
              sx={{
                width: ["100%", null, "10%"],
                order: [2, null, 1],
              }}
              py={2}
              px={[2, null, 0]}
            >
              <ProductGalleryThumbnails
                images={images}
                title={title}
                maxContainerHeight={600}
              />
            </Box>
          ) : (
            ""
          )}
          <Box
            sx={{ width: ["100%", null, "60%"], order: [1, null, 2] }}
            ml="auto"
            py={2}
            pr={images && images.length > 1 ? [2, null, 3] : [2, null, 3]}
            pl={images && images.length > 1 ? [2, null, 3] : [2, null, 0]}
            data-product-image-container
          >
            {/* Breadcrumbs block 1 for mobile */}
            <Box mb={1} sx={{ display: ["block", "block", "none"] }}>
              <Breadcrumbs
                productTitle={title}
                collectionTitle={collectionTitle}
                collectionPath={collectionPath}
                separator="/"
              />
            </Box>

            <ProductGalleryCarousel
              images={images}
              title={title}
              maxContainerHeight={productImagesCarouselProps.naturalSlideHeight}
            />
          </Box>

          <Flex
            sx={{
              width: ["100%", null, "40%"],
              flexDirection: "column",
              order: 3,
            }}
            px={[2, null, 3]}
            data-product-info
          >
            {/* Breadcrumbs block 2 for desktop */}
            <Box sx={{ display: ["none", "none", "block"] }} pt={1}>
              <Breadcrumbs
                productTitle={title}
                collectionTitle={collectionTitle}
                collectionPath={collectionPath}
                separator="/"
              />
            </Box>

            <CurrentVariantContextProvider>
              <Box>
                <Text as="h1" mb={3} data-title-box>
                  {title}
                </Text>
                <ProductVariantPrice
                  initialDisplayPrice={variants[0].price}
                  mb={3}
                />
                {shortDescription ? (
                  <DescriptionBox
                    source={shortDescription}
                    escapeHtml={false}
                    mb={3}
                  />
                ) : (
                  ""
                )}
              </Box>

              <ProductVariantSelector
                variants={variants}
                options={options}
                pageContext={pageContext}
                mb={4}
              />

              <Flex sx={{ alignItems: "center" }} mb={4}>
                <Box mr={2}>
                  <Text>{productQuantityLabel}</Text>
                </Box>
                <Box sx={{ width: "20%" }}>
                  <ProductCounter
                    decreaseAmount={decreaseAmount}
                    increaseAmount={increaseAmount}
                    currentAmount={currentAmount}
                  />
                </Box>
              </Flex>

              <Flex mb={4}>
                <Box>
                  <ProductVariantAddToCart
                    amount={currentAmount}
                    cartUrl={cartUrl}
                  />
                </Box>
              </Flex>

              <Flex mb={4}>
                <Box>
                  <Text>{paymentsLabel}</Text>
                  {/* <Payments payments={payments} /> */}
                </Box>
              </Flex>

              <Divider bg="grey" mb={4} />

              <ProductVariantSku />
            </CurrentVariantContextProvider>

            {vendor ? (
              <Flex mb={4}>
                <Box mr={2}>
                  <Text>{vendorLabel}</Text>
                </Box>
                <Box>{vendor}</Box>
              </Flex>
            ) : (
              ""
            )}

            {productType ? (
              <Flex mb={4}>
                <Box mr={2}>
                  <Text>{productTypeLabel}</Text>
                </Box>
                <Box>{productType}</Box>
              </Flex>
            ) : (
              ""
            )}

            <Flex mb={4} sx={{ alignItems: "center" }}>
              <Box mr={2}>
                <Text>{shareButtonsLabel}</Text>
              </Box>
              <Box>
                <ShareButtons buttons={shareButtons} location={location.href} />
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex pt={3} px={4} mx="auto" sx={{ maxWidth: 1300 }}>
          <Box sx={{ width: "100%" }}>
            <Divider bg="grey" mb={4} />

            <ProductDescription
              description={withoutShortDescription || descriptionHtml}
              sections={sections}
            />
          </Box>
        </Flex>

        {reviews && reviews.length > 0 ? (
          <ProductReviews
            reviews={reviews}
            reviewsNumber={reviews.length}
            paginationNum={reviewsNumberPerPage}
          />
        ) : (
          ""
        )}
      </CarouselProvider>
    </>
  );
}

export default ProductPage;
